* {
  scroll-behavior: smooth;
  scroll-padding-top: 55px;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a:not(.nav-links),
a:not(.nav-links):visited {
  color: #0057fc;
  text-decoration: none;
}

.service-links,
.service-links:visited {
  color: #fff !important;
  text-decoration: none !important;
}

.blog-links,
.blog-links:visited {
  color: #fff !important;
  text-decoration: none !important;
}

.footer-links,
.footer-links:visited {
  color: #fff !important;
  text-decoration: none !important;
}

.link-buttons,
.link-buttons:visited {
  color: #fff !important;
  text-decoration: none !important;
}

/* editor styles */
.ce-toolbar__plus svg {
  fill: white;
}

.ce-toolbar__plus:hover > svg {
  fill: #272739;
}

.ce-toolbar__settings-btn svg {
  fill: white;
}

.ce-toolbar__settings-btn:hover > svg {
  fill: #272739;
}

.ce-popover__item-icon {
  background: #000;
}

.ce-popover__item-label {
  color: #272739;
}

.ce-inline-toolbar__dropdown svg {
  color: #000;
}

.ce-conversion-tool {
  color: #000;
}

.ce-conversion-tool__icon {
  background: #000;
  color: #fff;
}

@media (max-width: 650px) {
  .ce-toolbar__plus svg {
    fill: black;
  }
  .ce-toolbar__settings-btn svg {
    fill: black;
  }
}
